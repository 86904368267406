var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("awaris-account")))]), _vm.hibah ? _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "hibah_type_id"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("mykad")))]), _vm.hibah.user_detail ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.hibah.user_detail.ic_number_dash) + " ")]) : _vm._e()]) : _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "ic_number"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("mykad")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('p', {
    staticClass: "form-control-static"
  }), _c('the-mask', {
    staticClass: "form-control",
    attrs: {
      "id": "ic_number",
      "mask": ['######-##-####']
    },
    model: {
      value: _vm.ic_number,
      callback: function ($$v) {
        _vm.ic_number = $$v;
      },
      expression: "ic_number"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "hibah_type_id"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("trust.type")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("estate-trust")))])])])]), _c('div', {
    staticClass: "text-right mt-3"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save-next")) + " ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }